import classNames from 'classnames'
import React from 'react'
import {NonStandardListStoryblok} from 'types/storyblok-types'
import {renderRichText, storyblokEditable} from '@storyblok/react'

type NonStandardListPropsType = {
  listType: NonStandardListStoryblok['type_of_list']
  listItems: NonStandardListStoryblok['list_items']
  blok: NonStandardListStoryblok
}

export const NonStandardList = ({
  listType,
  listItems,
  blok,
}: NonStandardListPropsType) => {
  const ListStyleClass = classNames({
    'list-image-[url(/images/icon-checkmark.svg)] ml-[35px] list-outside max-w-3xl':
      listType === 'unordered',
    'styled-ol': listType === 'ordered',
  })

  const listItemClass = classNames('mb-4', {
    'styled-ol-item': listType === 'ordered',
  })

  const ListTag =
    listType === 'ordered'
      ? (`ol` as keyof JSX.IntrinsicElements)
      : (`ul` as keyof JSX.IntrinsicElements)
  return (
    <ListTag
      role="list"
      className={`${ListStyleClass}`}
      {...storyblokEditable(blok)}
    >
      {listItems.map(item => {
        return (
          <li key={item._uid} className={`${listItemClass}`}>
            <div>
              {item.title ? (
                <span className="font-rubik font-medium">{item.title}</span>
              ) : null}
              {item.rich_text ? (
                <div
                  className="text-sm"
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(item.rich_text),
                  }}
                />
              ) : null}
            </div>
          </li>
        )
      })}
    </ListTag>
  )
}

const NonStandardListContainer = ({blok}: {blok: NonStandardListStoryblok}) => {
  return (
    <NonStandardList
      listType={blok.type_of_list}
      listItems={blok.list_items}
      blok={blok}
    />
  )
}

export default NonStandardListContainer
